var Cameraland = Cameraland || {};

Cameraland.CmsMenu = class {
    constructor(options) {
        this.hookEvents = this.hookEvents.bind(this);

        this.options = jQuery.extend({}, {
            cmsMenuSelector: '.cms-menu',
            cmsMenuItemSelector: '.cms-menu a',
        }, options);

        this.hookEvents();
    }

    hookEvents() {
        const path = window.location.pathname;

        jQuery(this.options.cmsMenuItemSelector).each(function() {
            const className = jQuery(this).attr('href');

            if(path == className) {
                jQuery(this).addClass('current');
            }
        });
    }
};

jQuery(function () {
    new Cameraland.CmsMenu();
});